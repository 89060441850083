import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Router from 'next/router';
import { Form, Field } from 'react-final-form';
import { createStyles, withStyles } from '@material-ui/core/styles';

import SearchInput from '../../../utility/SearchInput';
import combineStyles from '../../../../styles/combineStyles';
import * as types from '../../../../actions/actionTypes';
import { formatUrlAddress } from '../../../../lib/helpers';
import importedStyles from '../../../utility/SearchInput/styles';

import style from '../../../../styles/sass/components/HomeSearch.module.scss';
import StyleBase from '../../../../styles/base';
import { isMobile, isTablet } from '../../../../lib/getDeviceSize';

const styles = createStyles({
  input: {
    color: 'black',
    fontSize: 16,
    paddingTop: 2,
    width: 475,
    [StyleBase.media.tablet]: {
      width: 300,
    },
    [StyleBase.media.mobile]: {
      width: 230,
    },
  },
  inputSmall: {
    color: 'black',
    fontSize: 16,
    paddingTop: 2,
    width: 250,
  },
  inputSmallWithNewListing: {
    color: 'black',
    fontSize: 16,
    paddingTop: 2,
    width: 200,
  },
  inputWithNewListing: {
    color: 'black',
    fontSize: 16,
    paddingTop: 2,
    width: 400,
  },
  paper: {
    marginTop: 17,
    position: 'absolute',
    left: '0px',
    width: '100%',
    zIndex: 1,
  },
  paperSmall: {
    marginTop: 12,
    position: 'absolute',
    left: '0px',
    width: '100%',
    zIndex: 1,
  },
});
const combinedStyles = combineStyles(styles, importedStyles);

export interface Props {
  classes?: any;
  dispatch: any;
  searchHistory: any;
}

export interface State {
  id: number | undefined;
  isFocused: boolean;
  width: number;
}

export class HomeSearch extends React.Component<Props, State> {
  inputRef = React.createRef<HTMLInputElement>();
  constructor(props: any) {
    super(props);
    this.state = {
      id: undefined,
      isFocused: false,
      width: 0,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  setId = (id: number) => {
    this.setState({ id });
  };

  handleSubmit = (id: number, address: string, isListing: boolean) => {
    if (isListing) {
      Router.push(`/listing/r/${id}/${formatUrlAddress(null, address)}`);
    }
    this.props.dispatch({ type: types.HomeSearchComplete });
  };

  handleContainerFocus = () => {
    this.inputRef.current?.focus();
    this.toggleInputFocus(true);
  };

  toggleInputFocus = (isFocused: boolean) => {
    this.setState({
      isFocused,
    });
  };

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  render() {
    const { classes = {} } = this.props;
    const { isFocused, width } = this.state;
    const mobile = width && width <= 529;
    const newMatchesForSearchHistory =
      this.props.searchHistory.filter(
        (history: any) => history.numberOfNewMatches > 0,
      ).length > 0;
    let rootStyle = classes.input;
    if (mobile) {
      if (newMatchesForSearchHistory) {
        rootStyle = classes.inputSmallWithNewListing;
      } else {
        rootStyle = classes.inputSmall;
      }
    } else {
      if (this.props.searchHistory && newMatchesForSearchHistory) {
        rootStyle = classes.inputWithNewListing;
      } else {
        rootStyle = classes.input;
      }
    }
    return (
      <div
        className={`row center-xs middle-xs ${style.homeSearchContainer} ${
          isFocused ? style.active : ''
        }`}
        onClick={() => this.handleContainerFocus()}
      >
        <div className={`${style.homeSearchIcon}`} />
        <Form
          onSubmit={() => this.handleSubmit}
          render={({ handleSubmit, valid }) => (
            <Field
              allowPlaceSearch
              component={SearchInput}
              inputClasses={{}}
              additionalProps={{
                classes: {},
                fullWidth: true,
                id: 'homeSearch',
                label: '',
                name: 'searchListings',
                placeholder:
                  isTablet() || isMobile()
                    ? 'Enter a location'
                    : 'Enter a place, address, city, or zip code',
                required: true,
              }}
              inputRef={this.inputRef}
              InputProps={{
                classes: {
                  root: rootStyle,
                },
                disableUnderline: true,
              }}
              name="searchListings"
              onInputBlur={() => {
                this.toggleInputFocus(false);
              }}
              onInputFocus={() => {
                this.toggleInputFocus(true);
              }}
              paperStyles={mobile ? styles.paperSmall : styles.paper}
              style={{ width: '100%' }}
              results={(result: any, isListing: boolean) => {
                if (
                  !this.state.id ||
                  (result.id && this.state.id !== result.id)
                ) {
                  this.setId(result.id);
                }
                this.handleSubmit(result.id, result.address, isListing);
              }}
            />
          )}
        />
        {newMatchesForSearchHistory && (
          <div className={classes.newTagContainer}>
            <span className={classes.newTag}>NEW</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  searchHistory: state.feed.searchHistory,
});

export default connect(mapStateToProps)(withStyles(combinedStyles)(HomeSearch));
