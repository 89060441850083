import React, { useEffect, useState } from 'react';

const HeroImage = () => {
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    const images = [
      '/static/img/landing_pages/home/hero1.png',
      '/static/img/landing_pages/home/hero2.png',
      '/static/img/landing_pages/home/hero3.png',
      '/static/img/landing_pages/home/hero4.png',
      '/static/img/landing_pages/home/hero5.png',
      '/static/img/landing_pages/home/hero6.png',
    ];

    const randomImage = images[Math.floor(Math.random() * images.length)];
    setSelectedImage(randomImage);
  }, []);

  return (
    <div className="home-hero-image sell-hero-image indexed-landing-page-image">
      <img
        className="home-hero-image-two sell-hero-image-desktop indexed-landing-page-hero-image-desktop"
        alt="indexed-landing-page-hero-desktop"
        src={selectedImage}
      />
    </div>
  );
};

export default HeroImage;
