import * as React from 'react';
import { withRouter } from 'next/router';
import nextCookie from 'next-cookies';
import PageBase from '../components/layout/PageBase';
import ToriiLoader from '../components/utility/ToriiLoader';
import HomeLanding from '../components/pages/home/HomeLanding';

export interface Props {
  router: any;
}

export interface State {
  width: number;
}

export class Home extends React.Component<Props, State> {
  static async getInitialProps(ctx: any) {
    const { token } = nextCookie(ctx);
    if (ctx.req && token) {
      ctx.res.writeHead(302, { Location: '/dashboard' });
      ctx.res.end();
    }
    return {
      props: {}, // will be passed to the page component as props
    };
  }
  state = {
    width: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  render() {
    if (!this.state.width) {
      return <ToriiLoader top={150} />;
    }

    return (
      <PageBase
        title="Torii: Your home for all things home"
        overrideRemarks="The only all-in-one real estate brokerage combining expert real estate agents with breakthrough technology to help you find, buy, sell, and own your perfect home."
        disableOverflow
      >
        <div className="page-container home-container row center-xs">
          <HomeLanding />
        </div>
      </PageBase>
    );
  }
}

export default withRouter(Home);
