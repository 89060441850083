import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../../styles/index';
import { buttonStyles } from '../../../../styles/base';
import combineStyles from '../../../../styles/combineStyles';
import { CaretRight, MapPin, CheckCircle, XCircle } from 'phosphor-react';
import { Button, Link } from '@material-ui/core';
import Reviews from '../Reviews';
import router from 'next/router';
import { connect } from 'react-redux';
import { promptHelper } from '../../../../lib/helpers';
import { storageKeys } from '../../../../lib/storage';
import * as types from '../../../../actions/actionTypes';
import { isMobile } from '../../../../lib/getDeviceSize';
import HomeSearch from '../../../../components/pages/home/HomeSearch';

import style from '../../../../styles/sass/pages/Buy.module.scss';
import HeroImage from './HeroImage';

export interface Props {
  classes: any;
  token?: string;
  forgotDialogOpen: boolean;
  loginDialogOpen: boolean;
  resetPasswordDialogOpen: boolean;
  signupDialogOpen: boolean;
  welcomeDialogOpen: boolean;
  dispatch: any;
}

let TIMER: NodeJS.Timeout;
const SIGNUP_MODAL_DELAY = 15000;

export class HomeLanding extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.token) {
      this.startTimer();
    }
  }
  startTimer = () => {
    // Check if the timer is already running and clear it
    if (TIMER) {
      clearTimeout(TIMER);
    }
    TIMER = setTimeout(() => {
      promptHelper(storageKeys.homeVisitDate, this.conditionalOpenSignup);
    }, SIGNUP_MODAL_DELAY);
  };
  componentWillUnmount() {
    if (TIMER) {
      clearTimeout(TIMER);
    }
  }
  conditionalOpenSignup = () => {
    // Open the signup dialog if we haven't already opened another dialog
    const { props } = this;
    if (
      !props.forgotDialogOpen &&
      !props.loginDialogOpen &&
      !props.resetPasswordDialogOpen &&
      !props.signupDialogOpen &&
      !props.welcomeDialogOpen
    ) {
      this.openSignupModal();
    }
  };
  openSignupModal = () => {
    this.props.dispatch({
      type: types.OpenSignupDialog,
      postAuthPath: '/dashboard',
    });
  };
  renderPathsSection = () => {
    return (
      <section className="col-xs-12 center-xs middle-xs choose-your-path">
        <h1 className="choose-your-path-header">Choose your path</h1>
        <div className="path-choices">
          <Link href="/buy" className="buy-button path-button-mobile" />
          <Link href="/sell" className="sell-button path-button-mobile" />
          <Link
            href="/search-homes"
            className="search-button path-button-mobile"
          />
          <Link href="/join" className="agents-button path-button-mobile" />
        </div>
      </section>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {/* Section Hero */}
        <section
          className={`col-xs-12 home-hero-banner-light ${classes.homeBg}`}
          style={{ paddingBottom: isMobile() ? '0px' : '20px' }}
        >
          <div className="home-hero indexed-landing-page-hero">
            <h2 className="home-hero-heading-text indexed-home-hero-heading-text">
              Looking everywhere and still feel lost? We’ll get you home.
            </h2>
            <React.Fragment>
              <p
                className="home-hero-text indexed-home-hero-text"
                style={{ textAlign: 'left' }}
              >
                Your Torii agent will handle every step of the process when you
                buy or sell. Use Torii’s search and management dashboard to
                track everything and everyone involved in your transaction.
              </p>
            </React.Fragment>
            <div className="hero-search" style={{ margin: '32px 0px' }}>
              <HomeSearch />
            </div>
            <div className="hero-location-map" style={{ margin: '20px 0px' }}>
              <MapPin size={32} color="#FF7350" weight="fill" />
              <p>Available across all of Massachusetts</p>
            </div>
            <HeroImage />
          </div>
        </section>
        <section className="col-xs-12 video-container">
          <iframe
            width={isMobile() ? 750 : 750}
            height="420"
            src="https://www.youtube.com/embed/CsrakX1FNE8?si=kZPhdAdrO0oECW2r"
            title="We Are Torii Homes"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </section>
        <section className="col-xs-12 press-container press-container-left-aligned indexed-home-press-container">
          <a
            href="https://www.bostonglobe.com/2021/07/09/business/boston-home-buying-app-seeks-overhaul-industry-one-swipe-time/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/static/img/home/The_Boston_Globe-Dark.svg"
              className="press-icon boston-globe-icon"
              alt="Boston-Globe"
            />
          </a>
          <a
            href="https://www.inman.com/2022/04/11/alternative-brokerage-torii-is-innovating-home-search-management-tech-review/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/static/img/home/inman-logo-Dark.svg"
              className="press-icon inman-icon"
              alt="Inman"
            />
          </a>
          <a
            href="https://markets.businessinsider.com/news/stocks/boston-real-estate-startup-torii-expands-to-bay-area-1028813138"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/static/img/home/Business_Insider_Logo-Dark.svg"
              className="press-icon business-insider-icon"
              alt="Business-Insider"
            />
          </a>
        </section>

        {/* Section Path Details */}
        <section
          className="col-xs-12 center-xs middle-xs home-path-details"
          style={{ paddingTop: '0px', paddingBottom: '0px' }}
        >
          {/* Buy section */}
          <div className="detail-section" style={{ marginTop: '0px' }}>
            <div className="detail-section-image-container">
              <img
                src="/static/img/landing_pages/buying-section-image-light.png"
                alt="buy-section-image-desktop"
                className="detail-section-image-desktop"
              />
              <img
                src="/static/img/landing_pages/buying-section-image-light.png"
                alt="buy-section-image-tablet"
                className="detail-section-image-tablet"
              />
              <img
                src="/static/img/landing_pages/buying-section-image-light.png"
                alt="buy-section-image-mobile"
                className="detail-section-image-mobile"
              />
            </div>
            <div className="detail-section-text">
              <div
                className="detail-section-mini-heading"
                style={{ color: '#463264' }}
              >
                BUYING
              </div>
              <div className="detail-section-main-heading">
                The complete home buying experience
              </div>
              <div className="detail-section-sub-heading">
                Work with a local Torii real estate agent through the entire{' '}
                <br className="no-desktop-small no-tablet no-mobile" />
                home buying process, and we’ll set you up with a lender,{' '}
                <br className="no-tablet no-mobile" />
                closing services, and everything else you’ll need. On top of
                that, we’ll give you up to 10% of our commission back at close.
              </div>
              <Button
                className="detail-section-learn-more-button"
                style={{ backgroundColor: '#463264' }}
                onClick={() => router.push('/buy')}
              >
                Learn More
              </Button>
            </div>
          </div>

          {/* Sell Section */}
          <div className="detail-section">
            <div className="detail-section-text">
              <div
                className="detail-section-mini-heading"
                style={{ color: '#463264' }}
              >
                SELL
              </div>
              <div className="detail-section-main-heading">
                Sell your home, <br className="no-tablet no-mobile" />
                save thousands. <br className="no-tablet no-mobile" />
                Simple.
              </div>
              <div className="detail-section-sub-heading">
                Trust, communication, and market knowledge are what you get with
                a Torii agent. We will help price and market your home so it
                gives the best possible impression, both online and in-person.
                Your agent will then negotiate and oversee your deal to ensure
                the best possible price and smooth transaction.
              </div>
              <Button
                className="detail-section-learn-more-button"
                style={{ backgroundColor: '#463264' }}
                onClick={() => router.push('/sell')}
              >
                Learn More
              </Button>
            </div>
            <div className="detail-section-image-container selling-section">
              <img
                src="/static/img/landing_pages/selling-section-image-light.png"
                alt="sell-section-image-desktop"
                className="detail-section-image-desktop"
              />
              <img
                src="/static/img/landing_pages/selling-section-image-light.png"
                alt="sell-section-image-tablet"
                className="detail-section-image-tablet"
              />
              <img
                src="/static/img/landing_pages/selling-section-image-light.png"
                alt="sell-section-image-mobile"
                className="detail-section-image-mobile"
              />
            </div>
          </div>

          {/* Search Section  */}
          <div className="detail-section">
            <div className="detail-section-image-container image-left-align">
              <img
                src="/static/img/landing_pages/search-desktop.png"
                alt="search-section-image-desktop"
                className="detail-section-image-desktop"
              />
              <img
                src="/static/img/landing_pages/search-tablet.png"
                alt="search-section-image-tablet"
                className="detail-section-image-tablet"
                style={{ marginLeft: '0px', width: '100%' }}
              />
            </div>
            <div className="detail-section-text detail-section-text-tablet">
              <div
                className="detail-section-mini-heading"
                style={{ color: '#463264' }}
              >
                SEARCH
              </div>
              <div className="detail-section-main-heading">
                Search that really <br className="no-tablet no-mobile" />
                gets you
              </div>
              <div className="detail-section-sub-heading">
                Get straight to what you want with curated listing{' '}
                <br className="no-tablet no-mobile" />
                recommendations powered by AI. Is cooking your thing?{' '}
                <br className="no-tablet no-mobile" />
                See the kitchen first. Love to host? Only see homes with{' '}
                <br className="no-tablet no-mobile" />
                great living rooms and outdoor space.
              </div>
              <Button
                className="detail-section-learn-more-button"
                style={{ backgroundColor: '#463264' }}
                onClick={() => router.push('/search-homes')}
              >
                Start Searching
              </Button>
            </div>
            <div className="detail-section-image-container image-left-align mobile">
              <img
                src="/static/img/landing_pages/search-mobile.png"
                alt="search-section-image-mobile"
                className="detail-section-image-mobile"
              />
            </div>
          </div>

          {/*Section Why Torii*/}
          <section className="col-xs-12 reasoning">
            <div className="reasoning-container">
              <h1>Why Torii?</h1>
              <article className="reasons">
                <div
                  className="reason"
                  style={{
                    margin: '20px 0px 0px 0px',
                    backgroundColor: 'transparent',
                  }}
                >
                  <span className="reason-text no-mobile">&nbsp;</span>
                  <h6 className="column-one">Torii</h6>
                  <h6 className="column-two no-desktop">Others</h6>
                  <h6 className="column-two no-mobile no-tablet">
                    Traditional Brokerage
                  </h6>
                </div>
                <div className={`reason ${style.reasonIcon}`}>
                  <span className="reason-text">
                    Get back up to 10% of our commission
                  </span>
                  <span className="column-one">
                    <CheckCircle size={40} color="#195A50" weight="fill" />
                  </span>
                  <span className="column-two">
                    <XCircle size={40} color="#822841" weight="fill" />
                  </span>
                </div>
                <div className={`reason ${style.reasonIcon}`}>
                  <span className="reason-text">
                    Highly-vetted real estate agents
                  </span>
                  <span className="column-one">
                    <CheckCircle size={40} color="#195A50" weight="fill" />
                  </span>
                  <span className="column-two">
                    <XCircle size={40} color="#822841" weight="fill" />
                  </span>
                </div>
                <div className={`reason ${style.reasonIcon}`}>
                  <span className="reason-text">
                    Unmatched client experience
                  </span>
                  <span className="column-one">
                    <CheckCircle size={40} color="#195A50" weight="fill" />
                  </span>
                  <span className="column-two">
                    <XCircle size={40} color="#822841" weight="fill" />
                  </span>
                </div>
                <div className={`reason ${style.reasonIcon}`}>
                  <span className="reason-text">
                    Lender, insurance, and more all provided
                  </span>
                  <span className="column-one">
                    <CheckCircle size={40} color="#195A50" weight="fill" />
                  </span>
                  <span className="column-two">
                    <XCircle size={40} color="#822841" weight="fill" />
                  </span>
                </div>
                <div className={`reason ${style.reasonIcon}`}>
                  <span className="reason-text">
                    All-in-one home buying technology
                  </span>
                  <span className="column-one">
                    <CheckCircle size={40} color="#195A50" weight="fill" />
                  </span>
                  <span className="column-two">
                    <XCircle size={40} color="#822841" weight="fill" />
                  </span>
                </div>
                <div className={`reason ${style.reasonIcon}`}>
                  <span className="reason-text">
                    Personalized gifts and experiences
                  </span>
                  <span className="column-one">
                    <CheckCircle size={40} color="#195A50" weight="fill" />
                  </span>
                  <span className="column-two">
                    <XCircle size={40} color="#822841" weight="fill" />
                  </span>
                </div>
              </article>
            </div>
            <div className="get-started-button-container">
              <a
                className={`${style.baseBtn} ${style.talkToUsBorder}`}
                href="/contact"
                rel="noopener noreferrer"
              >
                Talk to us{' '}
                <CaretRight size={24} color="#195A50" weight="regular" />
              </a>
            </div>
          </section>
        </section>

        {/* Section Reviews */}
        <section className="col-xs-12 testimonials">
          <div className="blue-blob" />
          <div className="yellow-blob" />
          <Reviews
            autoSlide={true}
            title="Take their word for it"
            dontShowButtons={true}
          />
        </section>
      </React.Fragment>
    );
  }
}

const combinedStyles = combineStyles(buttonStyles, styles);

const mapStateToProps = (state: any) => ({
  forgotDialogOpen: state.dialogs.forgotDialogOpen,
  loginDialogOpen: state.dialogs.loginDialogOpen,
  resetPasswordDialogOpen: state.dialogs.resetPasswordDialogOpen,
  signupDialogOpen: state.dialogs.signupDialogOpen,
  token: state.auth.token,
  welcomeDialogOpen: state.dialogs.welcomeDialogOpen,
});

export default connect(mapStateToProps)(
  withStyles(combinedStyles)(HomeLanding),
);
